<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getRegisterUsageYearly, severityCode } from "@/services/api/energy.api";

const props = defineProps({
  registerId: {
    type: String,
  },
  unitType: {
    type: String,
  },
});

const usages = ref([]);

const headers = computed(() => {
  return [
    { text: "Jaar", value: "year" },
    { text: `Waarde ${props.unitType}`, value: "value" },
  ];
});

watch(
  () => props.registerId,
  () => {
    if (props.registerId) {
      fetchData();
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  const response = await getRegisterUsageYearly(props.registerId);
  if (response.severity <= severityCode.warning) {
    response.data.sort((a, b) => b.year - a.year);
    usages.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <v-data-table dense :headers="headers" hide-default-footer :items="usages"> </v-data-table>
  </v-container>
</template>
