<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { patchLocationRegisterDetails, postLocationRegisterDetails, getLocationRegisterDetails } from "@/services/api/location.api";
import { getRegisterDetail } from "@/services/api/energy.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useDetailEdit from "@/composables/detailEdit.js";
import { navigateToLocation } from "@/composables/general.js";
import RegisterUsageYearlyOverview from "@/components/energy/RegisterUsageYearlyOverview.vue";
import RegisterOverview from "@/components/energy/RegisterOverviewGrid.vue";
import DateInput from "@/components/general/DateInput.vue";

const { formatDateUtcD } = useDateTimeFormatter();
const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditDataMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  id: String,
  locationReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const register = ref({});
const dataMustBeRetrieved = ref(false);
const mainDataLoaded = ref(false);
const selectedRegisterId = ref(undefined);
const selectedTabIndex = ref(undefined);

const masterDataLoaded = computed(() => true);
const allDataLoaded = computed(() => (isAddingMasterMode.value && masterDataLoaded.value) || (!isAddingMasterMode.value && mainDataLoaded.value));
const isSaving = ref(false);
watch(
  () => [props.id, props.addModus],
  () => {
    console.log("LocationRegisterDetails, watch id+add: ", props.id, props.addModus);
    setMode(props.id, props.addModus);
    if (!isAddingMasterMode.value) {
      dataMustBeRetrieved.value = true;
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (!isAddingMode.value) {
      console.log("LocationRegisterDetails, masterDataLoaded, fetchData");
      fetchData();
    }
  }
);

watch(
  () => selectedData.value.registerId,
  () => {
    if (selectedData.value.registerId) {
      //pak registerid van een detail, registerid is bij alle details gelijk is
      selectedRegisterId.value = selectedData.value.registerId;
    }
  }
);

watch(
  () => selectedRegisterId.value,
  () => {
    if (selectedRegisterId.value) {
      getRegisterDetails();
    }
  }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("LocationRegisterDetails, fetchData", props.id);
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getLocationRegisterDetails(props.locationReference, props.id);
    console.log("LocationRegisterDetails, response ", response);
    if (response && !response.error) {
      selectedTabIndex.value = setDetails(response.data);
      mainDataLoaded.value = true;
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  console.log("LocationRegisterDetails, saveDetail");
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = Object.assign({}, selectedData.value);
      data.locationReference = props.locationReference;
      data.registerId = selectedRegisterId.value;
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
    }
    const result = await patchLocationRegisterDetails(props.locationReference, selectedData.value.detailId, data);
    if (!result.error) {
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van de koppeling zijn successvol gewijzigd.");
    } else {
      proxy.$toaster.error(result.error);
    }
  }
  isSaving.value = false;
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  console.log("LocationRegisterDetails, saveNewDetail", props.locationReference);
  const valid = await obs.value.validate();
  if (valid) {
    if (selectedRegisterId.value) {
      let data = {};
      data = Object.assign({}, selectedData.value);
      data.locationReference = props.locationReference;
      data.registerId = register.value.id;

      const response = await postLocationRegisterDetails(props.locationReference, data);

      if (!response.error) {
        proxy.$toaster.success("Gegevens van de koppeling zijn successvol toegevoegd.");
        if (isAddingMasterMode.value) {
          console.log("LocationRegisterDetails, isAddingMasterMode");
          navigateToLocation(props.locationReference.toString());
          console.log("LocationRegisterDetails, navigateToLocation");
        } else {
          //selectedData.id = response.data.id;
          setReadonlyMode();
          fetchData();
        }
      } else {
        proxy.$toaster.error(response.error);
      }
    } else {
      proxy.$toaster.error("Selecteer een meter");
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  if (isAddingMasterMode.value) {
    proxy.$toaster.warning(`Meter koppeling toevoegen geannuleerd.`);
    navigateToLocation(props.locationReference.toString());
  } else {
    proxy.$toaster.warning(`Meter koppeling versie toevoegen geannuleerd.`);
    setReadonlyMode();
    resetSelectedDetail();
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function getRegisterDetails() {
  const response = await getRegisterDetail(selectedRegisterId.value);

  if (response && !response.error) {
    register.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}

function unSelectRegister() {
  register.value = {};
  selectedRegisterId.value = undefined;
}

function selectedRegister(registerId) {
  selectedRegisterId.value = registerId;
}
</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message d-flex">
      <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van koppeling meter met locatie</h2>
      <h2 v-else-if="isAddingMasterMode">Toevoegen van koppeling meter met locatie</h2>
      <h2 v-else-if="isAddingDetailMode">Toevoegen van koppeling meter met locatie versie</h2>
      <h2 :class="{ loadingDots: !allDataLoaded }"></h2>
    </header>

    <v-row>
      <v-col>
        <v-container fluid>
          <v-row align="center">
            <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
            <v-col>
              <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3">
                <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" :class="{ noBorders: !isEditDataMode && !isEditTLMode }">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: !allowEdit && !allowEditTL }"
                        :outlined="allowEdit || allowEditTL"
                        :readonly="!allowEdit && !allowEditTL"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Bewerker" v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Datum" v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container v-if="selectedRegisterId" class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Details koppeling</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gebruiksfactor" rules="required|decimal:4|between:0,1" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Gebruiksfactor"
                        v-model="selectedData.allocFact"
                        :error-messages="errors"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field hide-details persistent-placeholder label="Paris proof score toekennings factor" v-model="register.ppsFactor" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
          </v-form>

          <masonry :cols="{ default: 1 }" :gutter="12">
            <v-container v-if="allDataLoaded && !selectedRegisterId" class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
              <header>Selecteer meter</header>
              <register-overview :isSelector="true" @selected-register="selectedRegister"></register-overview>
            </v-container>
          </masonry>

          <details-buttons
            :addMode="isAddingMode"
            :editMode="!isReadOnly"
            :allowSave="!isSaving"
            @edit="setEditMode"
            @edit-tl="setEditTLMode"
            @on-cancel="onCancel"
            @add-new-detail="addNewDetail"
            @on-cancel-add="onCancelAdd"
            @save-detail="saveDetail"
            @save-new-detail="saveNewDetail"
          />

          <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
            <v-container v-if="selectedRegisterId" class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
              <header>Metergegevens</header>
              <v-row dense v-if="addModus">
                <v-col cols="3"> </v-col>
                <v-col>
                  <v-btn dark class="primary my-1" title="UnSelectMeter" @click="unSelectRegister">
                    <v-icon dark left>mdi-checkbox-marked-circle-outline</v-icon>
                    Selecteer andere meter
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Referentie:</label>
                </v-col>
                <v-col>
                  {{ register.reference }}
                  <v-btn :to="{ name: 'EU-Register', params: { registerId: register.id, addModus: false } }" icon small plain>
                    <v-icon small> mdi-link-variant </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="3">
                  <label>Omschrijving:</label>
                </v-col>
                <v-col>
                  {{ register.description }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Type:</label>
                </v-col>
                <v-col>
                  {{ register.typeDescription }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Energietype:</label>
                </v-col>
                <v-col>
                  {{ register.energyTypeDescription }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Eenheid:</label>
                </v-col>
                <v-col> {{ register.unitTypeName }} ({{ register.unitTypeDescription }}) </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Bron:</label>
                </v-col>
                <v-col>
                  {{ register.source }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Bronreferentie:</label>
                </v-col>
                <v-col>
                  {{ register.sourceReference }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <label>Geldigheid:</label>
                </v-col>
                <v-col> {{ formatDateUtcD(register.validFrom) }} t/m {{ formatDateUtcD(register.validTill) }} </v-col>
              </v-row>
            </v-container>

            <v-container v-if="selectedRegisterId" class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
              <header>Verbruikgegevens</header>
              <register-usage-yearly-overview :registerId="register.id" :unitType="register.unitTypeName + ' (' + register.unitTypeDescription + ')'"></register-usage-yearly-overview>
            </v-container>
          </masonry>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
